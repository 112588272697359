import AboutAwardBlock from "slices/AboutAwardBlock";
import BigCalloutLinks from "slices/BigCalloutLinks";
import CardList from "slices/CardList";
import Columns from "react-bulma-components/lib/components/columns";
import Container from "react-bulma-components/lib/components/container";
import ContentSection from "slices/ContentSection";
import Dialog from "react-a11y-dialog";
import EventFinalistsGrid from "slices/EventFinalistsGrid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Footer from "components/Footer";
import FullWidthCallouts from "slices/FullWidthCallouts";
import FullWidthImage from "slices/FullWidthImage";
import Gallery from "slices/Gallery";
import Gallery2024 from "slices/Gallery2024";
import GlobalEmitter from "utils/GlobalEmitter";
import HTMLContent from "slices/HTMLContent";
import Helmet from "react-helmet";
import Hero from "slices/Hero";
import IFrameEmbed from "slices/IFrameEmbed";
import LargeSectionCallout from "slices/LargeSectionCallout";
import LectureList from "slices/LectureList";
import React from "react";
import Route from "route-parser";
import SponsorGroup from "slices/SponsorGroup";
import events from "utils/events";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import { getOgImageFromGallery } from "utils/getOgImageFromGallery";
import { graphql } from "gatsby";
import heroStyles from "slices/Hero/Hero.module.scss";
import { navigate } from "gatsby";
import { renderText } from "utils/renderHelpers";
import smoothScrollTo from "utils/smoothScrollTo";
import { sliceTypeMatches } from "utils/slices";

// Slices

// graphql

const getGuidelinesDialogContent = (
  stateObj,
  {
    guidelines_title,
    guidelines_subtitle,
    guidelines_top_paragraph,
    guidelines_jotform_embed_id,
    body1,
  }
) => {
  if (guidelines_title) {
    stateObj.dialogTitle = guidelines_title.text;
  }
  const hasFormEmbed =
    guidelines_jotform_embed_id && guidelines_jotform_embed_id.text;
  stateObj.dialogContents = (
    <React.Fragment>
      <Container>
        <Columns mobile multiline centered>
          <Columns.Column mobile={{ size: 11 }} tablet={{ size: 10 }}>
            <header>
              {renderText(guidelines_title, `h1`)}
              {renderText(
                guidelines_subtitle,
                `h4`,
                `has-text-red has-font-weight-bold`
              )}
            </header>
          </Columns.Column>
        </Columns>
        <Columns mobile multiline centered>
          <Columns.Column
            mobile={{ size: 11 }}
            tablet={{ size: hasFormEmbed ? 5 : 10 }}
          >
            <HTMLContent html={guidelines_top_paragraph} columns={1} />
          </Columns.Column>
          {hasFormEmbed && (
            <Columns.Column mobile={{ size: 11 }} tablet={{ size: 5 }} />
          )}

          {body1 &&
            body1.length &&
            body1.map(({ primary, items, slice_type, id }, idx) => {
              return (
                <Columns.Column
                  mobile={{ size: 11 }}
                  tablet={{ size: 10 }}
                  key={id}
                >
                  <HTMLContent columns={1} html={primary.heading} />
                  {items.map((item) => (
                    <HTMLContent
                      key={`${id}-${idx}`}
                      html={item.content}
                      columns={parseInt(primary.columns)}
                    />
                  ))}
                </Columns.Column>
              );
            })}
        </Columns>
      </Container>
    </React.Fragment>
  );
  return stateObj;
};

function parseFinalists(list, year, uid) {
  if (!uid) {
    return null;
  }
  return {
    edges: list.edges.filter((edge) => {
      let data = edge.node.data;
      if (data.award_finalist) {
        return data.award_finalist.find(
          (item) => item.year === year && item.award_link.raw.uid === uid
        );
      }
      return true;
    }),
  };
}

class EventPage extends React.Component {
  state = { dialogShowing: false, dialogTitle: null, dialogContents: null };

  static getDerivedStateFromProps(props) {
    if (typeof window === "undefined") {
      return null;
    }
    let loc = props.location.pathname;
    loc = loc.split("/");
    loc.shift();
    loc = loc[0] + "/" + loc[1];
    let routeParser = new Route(`/${loc}/:path(/)`);

    const { path } = routeParser.match(props.location.pathname);

    if (!path) {
      return { dialogShowing: false };
    }

    let state = { dialogShowing: true };

    switch (true) {
      case path === "guidelines":
        getGuidelinesDialogContent(state, props.data.page.data);
        break;
      default:
        state.dialogShowing = false;
        break;
    }
    return state;
  }

  static getPageMeta(globals, page, location) {
    let title = page.seo_title || page.title;

    let description = page.seo_description || globals.seo_description;
    let img_alt = page.seo_image_alt || globals.seo_image_alt || page.title;
    let fb_image = page.seo_image
      ? page.seo_image.facebook
      : globals.seo_image.facebook;

    // get a gallery image url
    const galleryImage = getOgImageFromGallery(page?.body);

    let twitter_image = page.seo_image
      ? page.seo_image.twitter
      : globals.seo_image.twitter;

    if (galleryImage) {
      fb_image = galleryImage;
      img_alt = galleryImage?.alt?.length > 0 ? galleryImage.alt : img_alt;
      twitter_image = galleryImage;
    }

    let pageTitle = `${title.text} | Writers' Trust of Canada`;
    let meta = [
      { property: `og:site_name`, content: pageTitle },
      { name: `title`, content: title.text },
      { property: `og:title`, content: title.text },
      { name: `description`, content: description.text },
      { property: `og:description`, content: description.text },
      { property: `og:image`, content: fb_image.url },
      {
        property: `og:image:width`,
        content: fb_image.dimensions ? fb_image.dimensions.width : null,
      },
      {
        property: `og:image:height`,
        content: fb_image.dimensions ? fb_image.dimensions.height : null,
      },
      { property: `og:image:alt`, content: img_alt.text },
      {
        property: `og:url`,
        content: `https://www.writerstrust.com${location.pathname}`,
      },
      {
        name: `twitter:card`,
        content: `summary_large_image`,
      },
      {
        name: `twitter:image`,
        content: twitter_image.url,
      },
    ];
    return meta.filter((m) => m.content);
  }

  componentDidMount() {
    setTimeout(() => {
      // window.scrollTo(0, 0);
      GlobalEmitter.on(events.resize, this.onResize);
      if (
        this.props.location.hash &&
        this.props.location.hash !== "" &&
        document.querySelector(this.props.location.hash)
      ) {
        setTimeout(() => {
          let hash = this.props.location.hash;
          let el = document.querySelector(hash);
          smoothScrollTo(el);
        }, 1000);
      }
    }, 750);
  }

  componentWillUnmount() {
    this.dialogDisabled = true;
    GlobalEmitter.off(events.resize, this.onResize);
  }

  onResize = () => {
    this.positionDialog();
  };

  positionDialog() {
    // if (this.state.dialogContents) {
    //   let dialog = this.dialog ? this.dialog.dialog : null;
    //   if (!dialog) {
    //     return;
    //   }
    //   clearTimeout(this.dialogTimeout);
    //   this.dialogTimeout = setTimeout(() => {
    //     let width = dialog.getBoundingClientRect().width;
    //     let height = dialog.getBoundingClientRect().height;
    //     dialog.style.top = `${Math.round(
    //       window.innerHeight * 0.5 - height * 0.5
    //     )}px`;
    //     dialog.style.left = `${Math.round(
    //       window.innerWidth * 0.5 - width * 0.5
    //     )}px`;
    //   }, 0);
    // }
  }

  renderSlice = (slice, nextSlice) => {
    if (!slice) {
      return null;
    }

    let result = null;
    let Cmp = null;
    let extras = {};
    const slice_type = slice.slice_type;
    switch (true) {
      case sliceTypeMatches(slice_type, "hero"):
        slice.primary = { ...slice.primary, theme: "grey" };
        if (slice.items[0].added !== true) {
          let hlink = null;
          if (this.props.data.page.uid.indexOf("margaret") >= 0) {
            hlink = `<a class="${
              heroStyles.internalLink
            }" href="/writers-books/programs/${
              this.props.data.page.slug.split("/")[1]
            }/all" title="Lecture History">Lecture History</a>`;
          }
          if (hlink) {
            slice.items.unshift({
              heading: { html: hlink },
              item_size: `one-fifth`,
              added: true,
            });
          }
        }
        extras.bottomTheme =
          nextSlice && nextSlice.primary && nextSlice.primary.theme
            ? nextSlice.primary.theme
            : null;
        extras.backLink = this.props.data.parentPage;
        Cmp = Hero;
        break;
      case sliceTypeMatches(slice_type, "full_width_callouts"):
        slice.primary = { ...slice.primary, theme: "grey" };
        Cmp = FullWidthCallouts;
        break;
      case sliceTypeMatches(slice_type, "full_width_callout_list"):
        let items = slice.items;
        if (items.length && !items[0].link) {
          items = slice.items
            .map((item) => {
              return item && item.callout && item.callout.document
                ? item.callout.document[0].data
                : null;
            })
            .filter(Boolean);
        }
        slice.items = items;
        slice.primary = { ...slice.primary };
        Cmp = FullWidthCallouts;
        break;
      case sliceTypeMatches(slice_type, "image_gallery"):
        Cmp = Gallery;
        break;
      case sliceTypeMatches(slice_type, "about_event_block"):
        Cmp = AboutAwardBlock;
        extras = { layout: 1 };
        break;
      case sliceTypeMatches(slice_type, "big_callout_links"):
        Cmp = BigCalloutLinks;
        break;
      case sliceTypeMatches(slice_type, "large_section_callout"):
        Cmp = LargeSectionCallout;
        break;
      case sliceTypeMatches(slice_type, "content_block"):
        Cmp = ContentSection;
        break;
      case sliceTypeMatches(slice_type, "sponsor_group"):
        Cmp = SponsorGroup;
        break;
      case sliceTypeMatches(slice_type, "full_width_image"):
        Cmp = FullWidthImage;
        break;
      case sliceTypeMatches(slice_type, "about_prize"):
        if (this.props.data.finalists || this.props.data.program_finalists) {
          let list =
            this.props.data.program_finalists &&
            this.props.data.program_finalists.edges &&
            this.props.data.program_finalists.edges.length > 0
              ? this.props.data.program_finalists
              : this.props.data.finalists;

          list = parseFinalists(
            list,
            this.props.data.page.data.year,
            this.props.data.page.data?.associated_award?.raw?.uid
          );

          const isProgram =
            this.props.data.program_finalists &&
            this.props.data.program_finalists.edges.length > 0;
          if (list?.edges?.length > 0) {
            Cmp = EventFinalistsGrid;
            extras = {
              heading:
                this.props.data.page.data?.associated_award_title || null,
              award: this.props.data.page.data.associated_award,
              year: this.props.data.page.data.year,
              autoSort:
                this.props.data.page.data?.associated_award_finalist_ordering
                  ?.length > 0
                  ? false
                  : true,
              isProgram,
            };
            list = list.edges.map(({ node }) => {
              return {
                writer: node,
                finalistData: node.data[
                  isProgram ? `programs` : `award_finalist`
                ].filter((f) => {
                  return (
                    f.year === this.props.data.page.data.year &&
                    f[isProgram ? `program` : `award_link`] &&
                    f[isProgram ? `program` : `award_link`].raw.uid ===
                      this.props.data.page.data.associated_award.raw.uid
                  );
                })[0],
              };
            });
            if (
              this.props.data.page.data?.associated_award_finalist_ordering
                ?.length > 0
            ) {
              const sortingArr =
                this.props.data.page.data.associated_award_finalist_ordering
                  .split(",")
                  .map((str) => str.trim().toLowerCase());
              list.sort(function (a, b) {
                const sort_title_a = a.writer.sort_title.split(" ")[0];
                const sort_title_b = b.writer.sort_title.split(" ")[0];
                return (
                  sortingArr.indexOf(sort_title_a) -
                  sortingArr.indexOf(sort_title_b)
                );
              });
            }
            slice.items = list;
          } else {
            Cmp = null;
          }
        }
        break;
      case sliceTypeMatches(slice_type, "card_list"):
        Cmp = CardList;
        break;
      case sliceTypeMatches(slice_type, "lecture_list"):
        Cmp = LectureList;
        break;
      case sliceTypeMatches(slice_type, "iframe_embed"):
        Cmp = IFrameEmbed;
        break;
      case sliceTypeMatches(slice_type, "photo_gallery_2024"):
        Cmp = Gallery2024;
        break;
      default:
        return null;
    }
    if (!Cmp && !result) {
      return null;
    }
    if (!Cmp && result) {
      return result;
    }
    return (
      <Cmp
        {...slice}
        key={slice.id}
        pageId={this.props.data.page.id}
        slug={this.props.data.page.slug}
        awardType={this.props.data.page.data.award_type}
        {...extras}
      />
    );
  };

  hideDialog = (e) => {
    document.documentElement.style.overflow = "auto";
    this.dialog.dialog.parentNode
      .querySelector(".dialog-overlay")
      .removeEventListener("click", this.hideDialog);
    this.dialog.off("hide", this.hideDialog);
    this.isDialogShowing = false;
    let loc = this.props.location.pathname;
    loc = loc.split("/");
    loc.shift();
    loc = loc[0] + "/" + loc[1];
    navigate(`/${loc}/`, { state: { modal: true } });
  };
  onShowDialog = () => {
    document.documentElement.style.overflow = "hidden";
  };

  componentDidUpdate() {
    if (this.dialog) {
      this.dialog.on("show", this.onShowDialog);
    }
    if (this.dialog && this.state.dialogShowing) {
      this.dialog.dialog.parentNode
        .querySelector(".dialog-overlay")
        .addEventListener("click", this.hideDialog);
      this.dialog.show();
      this.dialog.on("hide", this.hideDialog);
      this.isDialogShowing = true;
    } else if (
      this.dialog &&
      !this.state.dialogShowing &&
      this.isDialogShowing
    ) {
      this.dialog.off("hide", this.hideDialog);
      this.isDialogShowing = false;
      this.dialog.hide();
    }
  }

  shouldComponentUpdate(props) {
    if (props.location.pathname.indexOf(props.data.page.slug) === -1) {
      return false;
    }
    return true;
  }

  render() {
    const { page } = this.props.data;
    const { globals, footerData } = this.props.pageContext;
    const { title, body } = page.data;
    const { dialogTitle, dialogContents } = this.state;

    return (
      <React.Fragment>
        <Helmet
          bodyAttributes={{ class: `event-page grey` }}
          title={`${title.text} | Writers' Trust of Canada`}
          meta={EventPage.getPageMeta(
            globals.data,
            this.props.data.page.data,
            this.props.location
          )}
        />
        {body &&
          body.length > 0 &&
          body.map((slice, idx) => {
            return this.renderSlice(slice, body[idx + 1]);
          })}
        <Footer data={footerData} theme={page.data.footer_theme} />
        <Dialog
          id="award-page-dialog"
          appRoot="#__gatsby"
          dialogRoot="#dialog-root"
          dialogRef={(dialog) => (this.dialog = dialog)}
          title={dialogTitle || ``}
          closeButtonContent={
            <i className="icon">
              <FontAwesomeIcon icon={faTimes} />
            </i>
          }
          classNames={{
            element: "dialog-content",
            overlay: "dialog-overlay",
            base: "dialog",
            closeButton: "dialog-close",
            title: "is-sr-only",
          }}
        >
          {dialogContents || ``}
        </Dialog>
      </React.Fragment>
    );
  }
}

export const query = graphql`
  query ($uid: String, $year: Date, $awardId: String) {
    parentPage: prismicLandingPage(uid: { eq: "events" }) {
      slug
      data {
        title {
          text
        }
      }
    }
    page: prismicEventPage(uid: { eq: $uid }) {
      id
      uid
      slug

      data {
        snippets {
          snippet {
            text
          }
        }
        seo_title {
          text
        }
        seo_description {
          text
        }
        seo_image {
          twitter {
            url
            dimensions {
              width
              height
            }
          }
          facebook {
            url
            dimensions {
              width
              height
            }
          }
        }
        title {
          text
        }
        associated_award {
          raw {
            uid
            slug
            id
          }
        }
        associated_award_title {
          text
        }
        associated_award_finalist_ordering
        year
        footer_theme
        body {
          ... on PrismicEventPageBodyHero {
            id
            slice_type
            primary {
              image {
                url
                dimensions {
                  width
                  height
                }
              }
              category {
                text
              }
              heading {
                text
              }
              button_label {
                text
              }
              button_link {
                raw {
                  link_type
                  slug
                  uid
                  id
                }
              }
              bottom_callout_heading {
                text
              }
              bottom_callout_large_heading {
                text
              }
              bottom_callout_paragraph {
                html
                text
              }
              bottom_callout_button_label {
                text
              }
              bottom_callout_button_link {
                raw {
                  link_type
                  slug
                  uid
                  id
                }
              }
              show_side_panel
              enable_footer
              enable_floating_side_panel
              show_footer_item_borders
              footer_heading {
                text
              }
            }
            items {
              item_size
              image {
                url
                dimensions {
                  width
                  height
                }
              }
              heading {
                text
                html
              }
              link {
                url
                raw {
                  link_type
                  slug
                  uid
                  id
                }
              }
            }
          }
          ... on PrismicEventPageBodyIframeEmbed {
            id
            slice_type
            primary {
              theme
              width
              height
              responsive
              heading {
                text
              }
              embed_url
              embed_code {
                text
              }
            }
            items {
              iframe_parameter_name
              iframe_parameter_value
            }
          }
          ... on PrismicEventPageBodyFullWidthCallouts {
            id
            slice_type
            items {
              link {
                raw {
                  link_type
                  slug
                  uid
                  id
                }
              }
              image {
                url
                dimensions {
                  width
                  height
                }
              }
              category_heading {
                text
              }
              heading {
                text
              }
              label {
                text
              }
            }
          }
          ... on PrismicEventPageBodyImageGallery {
            id
            slice_type
            primary {
              theme
              heading {
                text
              }
              facebook_gallery_id {
                text
              }
            }
            items {
              image {
                url
                dimensions {
                  width
                  height
                }
              }
              caption {
                html
              }
            }
          }
          ... on PrismicEventPageBodyLargeSectionCallout {
            id
            slice_type
            primary {
              layout
              bleed
              category {
                text
              }
              heading {
                text
              }
              paragraph {
                html
                text
              }
              image {
                url
                dimensions {
                  width
                  height
                }
              }
            }
            items {
              link_label {
                text
              }
              link {
                url
                raw {
                  link_type
                  slug
                  uid
                  id
                }
              }
            }
          }
          ... on PrismicEventPageBodyContentBlock {
            id
            slice_type
            primary {
              columns
              heading {
                html
              }
            }
            items {
              content {
                html
              }
            }
          }
          ... on PrismicEventPageBodySponsorGroup {
            id
            slice_type
            primary {
              heading {
                text
              }
              size
            }
            items {
              heading {
                text
              }
              text_sponsor {
                text
              }
              sponsor {
                document {
                  data {
                    name {
                      text
                    }
                    url {
                      text
                    }
                    logo {
                      url
                      dimensions {
                        width
                        height
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicEventPageBodyFullWidthImage {
            id
            slice_type
            primary {
              theme
              image {
                url
                dimensions {
                  width
                  height
                }
              }
            }
          }
          ... on PrismicEventPageBodyCardList {
            id
            slice_type
            primary {
              category {
                text
              }
            }
            items {
              link {
                raw {
                  slug
                  uid
                  id
                }
              }
              description {
                html
              }
              cta_label {
                text
              }
            }
          }
          ... on PrismicEventPageBodyLectureList {
            id
            slice_type
            primary {
              anchor_id
              heading {
                text
              }
            }
            items {
              image {
                url
                dimensions {
                  width
                  height
                }
              }
              name {
                text
              }
              description {
                html
              }
              external_url {
                url
                raw {
                  link_type
                  target
                }
              }
              soundcloud_embed {
                title
                description
                embed_url
              }
            }
          }
          ... on PrismicEventPageBodyBigCalloutLinks {
            id
            slice_type
            primary {
              theme
            }
            items {
              link_label {
                text
              }
              link {
                url
                raw {
                  link_type
                  target
                  slug
                  uid
                  id
                }
              }
            }
          }
          ... on PrismicEventPageBodyAboutPrize {
            id
            slice_type
            primary {
              prize_link_label {
                text
              }
            }
          }
          ... on PrismicEventPageBodyAboutEventBlock {
            id
            slice_type
            primary {
              category {
                text
              }
              image {
                url
                dimensions {
                  width
                  height
                }
              }
              heading {
                text
                html
              }
              content {
                html
              }
            }
          }
          ... on PrismicEventPageBodyFullWidthCalloutList {
            id
            slice_type
            primary {
              theme
            }
            items {
              callout {
                document {
                  data {
                    link {
                      raw {
                        link_type
                        slug
                        uid
                        id
                      }
                    }
                    image {
                      url
                      dimensions {
                        width
                        height
                      }
                    }
                    category_heading {
                      text
                    }
                    heading {
                      text
                    }
                    label {
                      text
                    }
                  }
                }
              }
            }
          }
          ... on PrismicEventPageBodyPhotoGallery2024 {
            id
            slice_type
            primary {
              heading {
                text
              }
            }
            items {
              image {
                url
                alt
                dimensions {
                  width
                  height
                }
              }
            }
          }
        }
      }
    }
    program_finalists: allPrismicWriter(
      filter: {
        data: {
          programs: {
            elemMatch: {
              year: { eq: $year }
              program: { raw: { uid: { eq: $awardId } } }
            }
          }
        }
      }
    ) {
      edges {
        node {
          slug
          sort_title
          data {
            selected_works {
              primary {
                award {
                  raw {
                    id
                    uid
                    slug
                  }
                }
                year
              }
              items {
                book {
                  document {
                    slug
                    data {
                      short_story
                      title {
                        text
                      }
                      publish_date
                      image: cover_image {
                        url
                        carousel {
                          url
                        }
                      }
                      publisher {
                        document {
                          data {
                            name {
                              text
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            first_name {
              text
            }
            last_name {
              text
            }
            image {
              url
              carousel {
                url
              }
            }
            alternate_image {
              url
            }
            bio {
              text
              html
            }
            programs {
              year
              youtube_embed: embed {
                provider_name
                version
                embed_url
                height
              }
              program {
                raw {
                  id
                  slug
                  uid
                }
              }
            }
          }
        }
      }
    }
    finalists: allPrismicWriter(
      filter: {
        data: {
          award_finalist: {
            elemMatch: {
              award_link: { raw: { uid: { eq: $awardId } } }
              year: { eq: $year }
            }
          }
        }
      }
    ) {
      edges {
        node {
          slug
          sort_title
          data {
            selected_works {
              primary {
                award {
                  raw {
                    id
                    uid
                    slug
                  }
                }
                year
              }
              items {
                book {
                  document {
                    slug
                    data {
                      short_story
                      title {
                        text
                      }
                      publish_date
                      image: cover_image {
                        url
                        carousel {
                          url
                        }
                      }
                      publisher {
                        document {
                          data {
                            name {
                              text
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            first_name {
              text
            }
            last_name {
              text
            }
            image {
              url
              carousel {
                url
              }
            }
            alternate_image {
              url
            }
            bio {
              text
              html
            }
            award_finalist {
              year
              won
              award_link {
                raw {
                  uid
                  id
                  slug
                }
              }
              finalist_blurb {
                text
                html
              }
              citation {
                text
                html
              }
              citation_excerpt {
                text
              }
              citation_source {
                text
              }
              youtube_embed {
                provider_name
                version
                embed_url
                width
                height
              }
              book {
                document {
                  id
                  data {
                    short_story
                    title {
                      text
                    }
                    description {
                      text
                      html
                    }
                    cover_image {
                      url
                      carousel {
                        url
                      }
                    }
                    publisher {
                      document {
                        id
                        data {
                          name {
                            text
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

/*{
  finalists: allPrismicWriter(filter: {data: {programs: {elemMatch: {year: {eq: "2018"}, program: {raw: {uid: {eq: "writers-trust-fellowship"}}}}}}}) {
    edges {
      node {
        uid
      }
    }
  }
}
*/

export default EventPage;
