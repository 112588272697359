import React, { PureComponent } from 'react';
import Section from 'react-bulma-components/lib/components/section';
import Columns from 'react-bulma-components/lib/components/columns';
import Container from 'react-bulma-components/lib/components/container';
import classNames from 'classnames/bind';
import styles from './IFrameEmbed.module.scss';
const cx = classNames.bind(styles);

export default class IFrameEmbed extends PureComponent {
  static ID = 0;
  hasSetIframeHeight = false;
  state = { id: null, aspect: 56.25, iFrameHeight: 0 };

  componentDidMount() {
    IFrameEmbed.ID++;
    const { width, height } = this.props.primary;
    this.setState({
      id: IFrameEmbed.ID,
      aspect: (height / width) * 100,
      iFrameHeight: height
    });
  }
  render() {
    const { primary, items } = this.props;
    const { id, aspect, iFrameHeight } = this.state;
    const { embed_url, embed_code, heading } = primary;
    const hasEmbedCode = embed_code && embed_code.text;
    const theme = primary && primary.theme ? primary.theme : 'black';

    const responsive =
      (primary && primary.responsive === 'true') || primary.responsive === true;

    let iframeParams = {};
    if (!hasEmbedCode && items) {
      items.forEach(({ iframe_parameter_name, iframe_parameter_value }) => {
        if (iframe_parameter_name === `allowfullscreen`) {
          iframe_parameter_name = `allowFullScreen`;
        }
        iframeParams[iframe_parameter_name] = iframe_parameter_value || ``;
      });
    }

    return (
      <Section className={`${theme} ${cx({ section: true, [theme]: true })}`}>
        <Container>
          <Columns mobile centered>
            <Columns.Column mobile={{ size: 12 }} tablet={{ size: 12 }}>
              {heading && heading.text && (
                <h2 dangerouslySetInnerHTML={{ __html: heading.text }} />
              )}
              {hasEmbedCode ? (
                <article
                  ref={r => (this.container = r)}
                  id={`iframe-${id}`}
                  className={cx({
                    embedContainer: true,
                    responsive: responsive === true || responsive === 'true'
                  })}
                  style={
                    responsive
                      ? { paddingTop: `${aspect}%` }
                      : { height: iFrameHeight }
                  }
                  dangerouslySetInnerHTML={{ __html: embed_code.text }}
                />
              ) : (
                <article
                  ref={r => (this.container = r)}
                  id={`iframe-${id}`}
                  className={cx({
                    embedContainer: true,
                    responsive: responsive === true || responsive === 'true'
                  })}
                  style={
                    responsive
                      ? { paddingTop: `${aspect}%` }
                      : { height: iFrameHeight }
                  }
                >
                  <iframe
                    ref={r => (this.frame = r)}
                    src={embed_url}
                    title={`iframe-${id}`}
                    onLoad={this.onResize}
                    frameBorder={0}
                    {...iframeParams}
                  />
                </article>
              )}
            </Columns.Column>
          </Columns>
        </Container>
      </Section>
    );
  }
}
